<template>
    <div class="modal-profile-item-selector edit-profile-detail cancel-schedule bg-main p-20">
        <StackRouterHeaderBar :class="{ 'bottom-border': false }" :show-title="true" :leftButtonHandler="back" />

        <div class="title f-bold f-18 m-t-100">
            약속 변경이 아니라 <br />
            약속을 취소하시겠어요?
        </div>
        <section class="section m-t-100">
            <div class="content">
                <div class="content-title">취소 수수료 안내</div>
                <div class="desc f-medium li-dot m-t-6">약속 변경 시 → 수수료 없음</div>
                <div class="desc f-medium li-dot m-t-6">
                    약속 취소 시 → <span style="color: red; text-decoration: underline">5만원</span>
                </div>
                <div class="desc f-medium li-dot m-t-6">
                    (약속 취소하지 않고) 약속 No Show, <br />
                    잠수 → <span style="color: red; text-decoration: underline">150,000원</span>
                </div>
            </div>

            <div class="guide-content">
                <div class="text flex-row f-500">
                    <p class="dot m-r-8 m-b-3 m-t-12" />
                    <span class="m-t-6">
                        약속 진행이 어려운
                        <span
                            style="color: red; text-decoration: underline; font-style: italic"
                            @click="onClickFeeInformation"
                            >부득이한 사유(자세히 보기)</span
                        >의 경우는 약속 취소 시에도 수수료가 면제됩니다.
                    </span>
                </div>
                <div class="text flex-row f-500">
                    <p class="dot m-r-8 m-b-3 m-t-12" />
                    <span class="m-t-6"> 취소 수수료 납부 시 사용한 매칭권은 복구 처리 됩니다. </span>
                </div>
            </div>
        </section>
        <!-- <BottomButton @click="submit" :label="'약속 취소하기'" /> -->

        <div class="send-btn m-l-20 m-r-20">
            <button class="send-action-btn f-14" @click="onClickSumbit('change')">약속 변경하기</button>
        </div>
        <div class="send-btn m-l-20 m-r-20 m-t-18">
            <button class="send-action-btn cancel-action f-14" @click="onClickSumbit('cancel')">약속 취소하기</button>
        </div>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalConfirmSchedule',
    props: ['options'],
    data: () => ({}),
    methods: {
        back() {
            this.$router.go(-1)
        },
        async onClickSumbit(status) {
            if (status === 'change') {
                await this.$modal.custom({
                    component: 'ModalChangeSchedule',
                    options: {
                        schedule: this.options.schedule,
                    },
                })
            } else {
                await this.$modal
                    .basic({
                        body:
                            '취소 요청과 동시에 약속은 취소 처리되며 되돌릴 수 없습니다. 정말 약속을 취소하시겠습니까? <br>약속 취소 시 5만원의 취소 수수료가 부과됩니다.<br>(부득이한 사유의 경우 증빙 자료를 제출해 취소 수수료를 면제받을 수 있습니다.)',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(async idx => {
                        if (idx === 1) {
                            const schedule = this.options.schedule
                            const payload = {
                                id: schedule.schedule_id,
                                user_id: this.me.user_id,
                                change_type: 'cancel',
                                reason: this.options.reason,
                            }
                            try {
                                const res = await scheduleService.changeScheduleRequest(payload)
                                this.$emit('close', 'canceled')
                                this.$toast.success(res.msg)
                                // this.$router.go(-2)
                            } catch (err) {
                                console.error(err)
                            }
                        }
                    })
            }
        },
        async onClickFeeInformation() {
            this.$modal.custom({
                component: 'ModalFeeInformation',
            })
        },
    },
    computed: {
        scheduleTime() {
            const date = new Date(this.options.schedule.date_confirmed)
            const dayArr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            const scheduleTime = `${date.getMonth() + 1}월 ${date.getDate()}일 ${this.$translate(
                dayArr[this.$moment(date).day() % 7]
            )}요일 ${date.getHours()}시 ${date.getMinutes().toString().padEnd(2, '0')}분`

            return scheduleTime
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>
<style scoped lang="scss">
.cancel-schedule {
    overflow-y: auto;

    .title {
        margin: 58px 0 12px 0;
        margin-bottom: 50px;
    }

    .section {
        margin-bottom: 30px;
        padding: 0 16px;
        color: black;

        .guide-content {
            background: $grey-01;
            padding: 20px;
            font-size: 13.5px;
            margin-top: 50px;
        }
        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-bottom: 8px;

            @include spoqa-f-medium;
        }

        .content {
            font-size: 16px;
            margin-top: 50px;
            .content-title {
                color: #151360;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .type-btn {
                font-size: 14px;
                color: $grey-06;
                padding-left: 16px;
                border: 1px solid $grey-02;
            }
            .desc {
                &.li-dot {
                    display: list-item;
                    align-items: flex-start;
                    margin-left: 20px;
                }
            }

            .place-map {
                margin-left: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 69px;
                width: fit-content;
                height: 24px;
                background: $grey-03;
                border-radius: 100px;
                color: #111;
            }
            .place-wrap {
                display: flex;
                align-items: center;
            }
        }

        .guide {
            margin-top: 80px;
            color: #111;
            font-weight: 500;
            font-size: 15px;
            line-height: 19px;
            padding: 20px;
        }
    }

    .send-btn {
        position: relative;
        display: flex;
        align-items: center;

        .send-action-btn {
            width: 100%;
            height: 48px;
            background-color: #ddd0da;
            color: #151360;
            border: none;
            border-radius: 5px;

            &.cancel-action {
                background-color: #151360;
                color: white;
            }
        }
    }
}
</style>
